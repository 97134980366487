import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import Header from '../components/Header';
import calendario from '../assets/images/calendario.svg';

const HeaderTextos = styled.div`
  padding: 0 1rem 1rem 1rem;
  width: 70%;
  margin: 0 auto;
  p {
    padding: 1rem;
    background-color: var(--color_5);
    border-radius: 3px;
    font-style: italic;
    box-shadow: 1px 1px 2px 2px whitesmoke;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ArticulosGrid = styled.div`
  margin-top: 2rem;
  width: 70%;
  margin: 0 auto;
  display: grid;
  align-self: center;
  grid-gap: 2rem;
  grid-template-rows: repeat(auto-fill, max(1fr, 250px));

  @media (max-width: 800px) {
    width: 90%;
  }

`;

const TarjetaArticuloStyle = styled.div`
  //border: 1px solid var(--color_1);
  padding: 3rem;
  background-color: var(--color_5);
  transition: 0.3s;

  :hover {
    background-color: var(--color_6);
    scale: 1.01;
  }

  .tarjeta-titulo {
    //background-color: var(--color_0);
    display: inline-block;
    padding: 3px 0px 5px 0px;
    margin-bottom: 3px;
    font-size: 2.1rem;
    font-weight: bold;
  }

  .tarjeta-fecha {
    display: flex;
    height: 3rem;
    align-items: center;
    color: var(--color_negro_2);
    img {
      padding-right: 0.5rem;
      padding-bottom: 1px;
    }
  }

  .texto-resumen {
    font-size: 2.1rem;

  }

  .link2-mas {
    text-decoration: underline;
    text-decoration-color: var(--color_0);
  }
`;

export default function ArticlesPage({ data, pageContext }) {
  const articulos = data.articulos.nodes;
  return (
    <>
      <SEO title="Artículos" />
      <Header />
      <Nav categorias={data.categorias.nodes}/>
      <Pagination 
        pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
        totalCount={data.articulos.totalCount}
        currentPage={pageContext.currentPage || 1}
        skip={pageContext.skip}
        base={`/articulos/${pageContext.categoria}`}
      />
      <HeaderTextos>
        <p>{data.desc.edges[0].node.description}</p>
      </HeaderTextos>
      <ArticulosGrid>
        {articulos.map(articulo => {
          var fecha_str = articulo.publishedAt.toString()
          var year = fecha_str.slice(0, 4);
          var month = fecha_str.slice(5, 7);
          var day = fecha_str.slice(8, 10); 
          return (
            <Link key={"tarjeta_" + articulo.id} to={`/articulo/${year}/${month}/${day}/${articulo.slug.current}`} style={{ textDecoration: 'none' }}>
            <TarjetaArticuloStyle key={"tarj_resumen" + articulo.id}>
              <h3 className="tarjeta-titulo" key={"h2_resumen" + articulo.id}>{articulo.title}</h3>
              <div className="tarjeta-fecha" key={"div_resumen" + articulo.id}>
                <img src={calendario} align="center" alt="Calendario" height="15px"/> 
                <h4 key={"h4_resumen" + articulo.id}>{day}-{month}-{year}</h4>
              </div>
              <p className="texto-resumen" key={"p_resumen" + articulo.id}>{articulo.body[0].children[0].text}</p>
              <p key={"p2_resumen" + articulo.id}>
                <span key={"span2_resumen" + articulo.id} className="link2-mas">Leer más...</span>
              </p>
            </TarjetaArticuloStyle>
            </Link>
          )
        })}
      </ArticulosGrid>
      <button id="scroll-btn" className="sidebar-btns" title="Scroll to top"></button>
      <Pagination 
        pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
        totalCount={data.articulos.totalCount}
        currentPage={pageContext.currentPage || 1}
        skip={pageContext.skip}
        base={`/articulos/${pageContext.categoria}`}
      />
    </>
  );
}

export const query = graphql`
  query($skip: Int! = 0, $pageSize: Int = 40, $categoria: String) {
    articulos: allSanityPost(sort: {fields: publishedAt, order: DESC}, limit: $pageSize, skip: $skip, filter: {categories: {elemMatch: {title: {eq: $categoria}}}}) {
      totalCount
      nodes {
        title
        id
        slug {
          current
        }
        author {
          name
        }
        publishedAt
        body {
          children {
            text
          }
        }
      }
    }
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
    desc: allSanityCategory(filter: {title: {eq: $categoria}}) {
      edges {
        node {
          description
        }
      }
    }
  }
`;
